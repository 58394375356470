import React from 'react';
import { styled } from 'styled-components';
import { withMatch } from '../../../contexts/MatchContext';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useMatch } from '../../../hooks/useMatch';
import { hasCards, hasShootout } from '../../../services/dwfOptions';
import { theme } from '../../templates/ui';
import Goal from '../Icons/Goal';
import PenaltyCardsMatchHeader from '../Icons/PenaltyCardsMatchHeader';

const StyledContainer = styled.div`
  text-align: center;
  @media only screen and (min-width: ${theme.breakpoints.small}) {
    display: inline-block;
  }
`;

const StyledBoxedContainer = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;

  @media only screen and (min-width: ${theme.breakpoints.small}) {
    flex-direction: column;
  }

  @media only screen and (max-width: ${theme.breakpoints.small}) {
    display: inline-block;
    width: 100%;
  }
`;

const StyledBoxedShootoutContainer = styled.div`
  border-radius: 4px;
  margin-top: ${theme.sizing.small};

  > div > div {
    border: none;
  }
`;

const StyledScoreInput = styled.input`
  border-radius: 6px;
  padding: ${theme.sizing.small};
  height: 42px;
  text-align: center;
  font-size: ${theme.font.xxxxlarge};
  color: ${theme.color.base.base};
  background-color: ${theme.color.gray.white};
  font-weight: 600;
  width: 100%;
  -moz-appearance: textfield;
  border: 2px solid ${theme.color.gray.alt};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: 0;
    border-color: ${theme.color.primary.base};
  }
`;

const StyledShootOutContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const StyledShootOutWrapper = styled.div`
  position: relative;

  @media only screen and (min-width: ${theme.breakpoints.small}) {
    border-top: 1px solid ${theme.color.secondary.base};
    border-left: 0;
  }
`;

const StyledShootOutInput = styled.input`
  border: 2px solid ${theme.color.gray.alt};
  border-radius: 6px;
  display: inline-block;
  width: 100%;
  padding: ${theme.sizing.small} ${theme.sizing.small} ${theme.sizing.small} ${theme.sizing.xxlarge};
  font-weight: 600;
  text-align: center;
  -moz-appearance: textfield;
  background-color: ${theme.color.gray.white};

  @media only screen and (min-width: ${theme.breakpoints.small}) and (max-width: 460px) {
    padding: ${theme.sizing.small} ${theme.sizing.small} ${theme.sizing.small}
      ${theme.sizing.xxxxlarge};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 0;
    border-color: ${theme.color.primary.base};
  }
`;

const StyledGoal = styled(Goal)`
  transform: translateY(-50%);
  position: absolute;
  left: ${theme.sizing.medium};
  top: 50%;
  height: ${theme.sizing.xxlarge};
  width: 23px;
  pointer-events: none;
  fill: ${theme.color.gray.light};

  ${(props) => props.higher && `fill: ${theme.color.secondary.base};`};
`;

const StyledPenaltyCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.sizing.small};
`;

const StyledPenaltyIcon = styled(PenaltyCardsMatchHeader)`
  width: 15px;
  height: ${theme.sizing.xxlarge};
  margin-right: ${theme.sizing.small};
`;

const StyledAmount = styled.span`
  font-weight: 700;
`;

const ScoreContainerComponent = ({
  id,
  className,
  goalScore,
  shootoutScore,
  higher,
  cardsAmount,
  updateScore,
  updateShootout,
  matchGame,
  scoreTabIndex,
  shootOutTabIndex,
  ...props
}) => {
  const { canUpdateMatchScore } = useHasPermission();
  const { isDraw } = useMatch();

  return (
    <StyledContainer className={className}>
      <StyledBoxedContainer>
        <StyledScoreInput
          id={id}
          type={'number'}
          value={goalScore}
          placeholder="-"
          onChange={updateScore}
          disabled={!canUpdateMatchScore}
          higher={higher}
          data-testid={props['data-scoreInputTestId']}
          min="0"
          max="99"
          tabIndex={scoreTabIndex}
        />
      </StyledBoxedContainer>
      {hasShootout(matchGame.dwf_options) && isDraw && (
        <StyledBoxedShootoutContainer>
          <StyledShootOutContainer>
            <StyledShootOutWrapper>
              <StyledGoal higher={higher} />
              <StyledShootOutInput
                type={'number'}
                value={shootoutScore}
                onChange={updateShootout}
                disabled={!canUpdateMatchScore}
                data-testid={props['data-shootoutInputTestId']}
                min="0"
                max="99"
                tabIndex={shootOutTabIndex}
              />
            </StyledShootOutWrapper>
          </StyledShootOutContainer>
        </StyledBoxedShootoutContainer>
      )}
      {hasCards(matchGame.dwf_options) && (
        <StyledPenaltyCards>
          <StyledPenaltyIcon />
          <StyledAmount>{cardsAmount || 0}</StyledAmount>
        </StyledPenaltyCards>
      )}
    </StyledContainer>
  );
};

export const ScoreContainer = withMatch(ScoreContainerComponent);
